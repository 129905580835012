<div class="feedback-button" (click)="openModal()">
    <svg-icon class="feedback-ico" src="/assets/images/logo-m.svg" />
    <b class="feedback-text small">Обратная связь</b>
</div>

<app-modal [size]="modalSize" (onClose)="closeFeedback()">
    @if (!isSuccess) {
        <div class="feedback-form">
            <h1 class="center">Обратная связь</h1>
            <p class="center big">
                Мы постоянно работаем над удобством и расширением функционала
                личного кабинета. Если у вас есть предложения, мы обязательно
                учтем их при разработке.
            </p>
            <p class="feedback-form-subtitle big">
                <label for="{{ textFieldParams.id }}"
                    ><b>Что можно улучшить?</b></label
                >
            </p>
            <app-textarea
                [params]="textFieldParams"
                [control]="form.controls.message"
            />
            <div class="feedback-form-controls">
                <app-button
                    [loading]="loading"
                    [classes]="['btn-high']"
                    (onClick)="onSendClick()"
                    text="Отправить"
                />
                <app-button
                    [loading]="loading"
                    [classes]="['btn-high', 'btn-outline']"
                    (onClick)="onCancelClick()"
                    text="Отменить"
                />
            </div>
        </div>
    } @else {
        <div class="feedback-ty center">
            <h1>Спасибо за отзыв</h1>
            <p class="big">
                Мы ценим, что вы делитесь своим опытом использования сервиса и
                сделаем все возможное, чтобы использование Мегаптеки было для
                вас удобным
            </p>
            <div class="feedback-ty-pic">
                <svg-icon
                    class="feedback-ico"
                    src="/assets/images/unicorn-space.svg"
                />
            </div>
        </div>
    }
</app-modal>

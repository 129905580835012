import { Injectable, inject } from '@angular/core';
import { ApiFeedbackService } from '@merchShared/service/methods/feedback';

@Injectable()
export class FeedbackService {
    private _apiFeedbackService = inject(ApiFeedbackService);

    public sendFeedback(message: string) {
        return this._apiFeedbackService.sendMessage({ message: message });
    }
}

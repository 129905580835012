import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { PageLayoutComponent } from '@shared/layouts/page-layout/page-layout.component';
import { FeedbackComponent } from '@merchShared/ui-elements/feedback/feedback.component';
import { PAGES_ROUTES } from './pages.routes';
import { ITEMS_ROUTES } from './items/items.routes';
import { NavigationScrollService } from '@shared/service/navigation-scroll/navigation-scroll.service';
import { ROBOT_ROUTES } from './optimus-price/robot.routes';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    standalone: true,
    imports: [PageLayoutComponent, FeedbackComponent],
})
export class PagesComponent implements OnInit, OnDestroy {
    private _navigationScrollService = inject(NavigationScrollService);

    public routes = PAGES_ROUTES;
    public subMenus = [
        {
            path: 'items',
            routes: ITEMS_ROUTES,
        },
        {
            path: 'optimus-price',
            routes: ROBOT_ROUTES,
        },
    ];

    ngOnInit(): void {
        this._navigationScrollService.ngOnInit();
    }

    ngOnDestroy(): void {
        this._navigationScrollService.ngOnDestroy();
    }
}

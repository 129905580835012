import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ViewChild,
    inject,
} from '@angular/core';
import {
    ModalComponent,
    Sizes,
} from '@shared/ui-elements/modal/modal.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TextareaComponent } from '@shared/fields/textarea/textarea.component';
import { ButtonComponent } from '@shared/controls/button/button.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ParamsTextarea } from '@shared/fields/textarea/interface';
import { FeedbackService } from './feedback.service';
import { Toast } from '@core/service/toast';

@Component({
    selector: 'app-feedback',
    standalone: true,
    imports: [
        ModalComponent,
        AngularSvgIconModule,
        TextareaComponent,
        ButtonComponent,
    ],
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
    providers: [FeedbackService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackComponent {
    private _service = inject(FeedbackService);
    private _toast = inject(Toast);
    private _cdr = inject(ChangeDetectorRef);

    @ViewChild(ModalComponent) modal!: ModalComponent;

    public modalSize: Sizes = 'm';
    public loading = false;
    public isSuccess = false;
    public form: FormGroup<{ message: FormControl<string | null> }> =
        new FormGroup({
            message: new FormControl<string | null>(null, [
                Validators.required,
            ]),
        });
    public textFieldParams: ParamsTextarea = {
        id: 'message',
        placeholder: 'Текст сообщения',
    };

    public openModal() {
        this.modal.open();
    }

    public closeFeedback() {
        this.form.reset();
        this.isSuccess = false;
        this.modalSize = 'm';
        this.loading = false;
        this._cdr.detectChanges();
    }

    public onCancelClick() {
        this.modal.close();
    }

    public onSendClick() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.loading = true;
            const message = this.form.getRawValue().message + '';

            this._service.sendFeedback(message).subscribe({
                next: () => {
                    this.isSuccess = true;
                    this.modalSize = 's';
                    this.loading = false;
                    this.form.reset();
                    this._cdr.detectChanges();
                },
                error: (err) => {
                    this.loading = false;
                    this._toast.error(err.message);
                    this._cdr.detectChanges();
                },
            });
        }
    }
}

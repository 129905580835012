import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@core/service/api';
import {
    Drugs,
    DrugsDetail,
    DrugsEdit,
    DrugsService,
    ParamsDrugs,
    ParamsDrugsDetail,
    ParamsDrugsEdit,
} from '@shared/service/methods/drugs';

@Injectable({
    providedIn: 'any',
})
export class ApiDrugsService implements DrugsService {
    private _apiService = inject(ApiService);

    list(
        obj?: ParamsDrugs,
        needPagination?: boolean,
        acceptableValues?: string[]
    ): Observable<Drugs> {
        return this._apiService
            .get(
                'merch-lk/v1/drugs',
                this._apiService.prepareParams(
                    obj,
                    needPagination,
                    acceptableValues
                )
            )
            .pipe(map((res) => res.json));
    }

    detail(obj: ParamsDrugsDetail): Observable<DrugsDetail> {
        return this._apiService
            .get('merch-lk/v1/drugs/detail', obj)
            .pipe(map((res) => res.json));
    }

    edit(obj: ParamsDrugsEdit): Observable<DrugsEdit> {
        return this._apiService
            .post('merch-lk/v1/drugs/detail/update', obj)
            .pipe(map((res) => res.json));
    }
}

import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@core/service/api';
import {
    AuthLogin,
    LoginService,
    ParamsAuthLogin,
} from '@shared/service/methods/auth/login';

@Injectable({
    providedIn: 'root',
})
export class ApiAuthLoginService implements LoginService {
    private _apiService = inject(ApiService);

    login(obj: ParamsAuthLogin): Observable<AuthLogin> {
        return this._apiService
            .post('merch-lk/v1/auth/login', obj)
            .pipe(map((res) => res.json));
    }
}
